import React from 'react';
import './parallax-component.scss';
import BackgroundImage from "gatsby-background-image"
import scrollTo from "gatsby-plugin-smoothscroll";


const ParallaxComponent = ({ props }) => {

    
    var bgFluidImage = { fluid: { srcWebp: props.image, src: props.pngImage } }

    return (
        <div>
            <BackgroundImage Tag="section" fluid={bgFluidImage.fluid} role="img" aria-label="stop-sign" keepStatic={true} className={props.banner == true ? "bannerSection_parallax" : "firstSection_parallax"}>
                {/* <div className={props.banner != '' ? "bannerSection_parallax" : "firstSection_parallax"}> */}
                <div className="overlay" style={{ backgroundColor: props.color }}>
                    <div className="content">
                        {
                            props.banner == true ?
                                props.h1 ?
                                    <h1>{props.title}</h1>
                                    :
                                    <h2>{props.title}</h2>
                                :
                                <h1>{props.title}</h1>
                        }
                        {
                            props.secondTitle ?
                                <h2>{props.secondTitle}</h2>
                                : ""

                        }
                        {
                            props.description ?
                                <p>{props.description}</p>
                                : ""

                        }
                        
                        {
                            props.CTALabel ?
                                props.CTALabel == "TELL ME MORE" ?
                                    <button href={props.CTAUrl} className="btn" onClick={() => scrollTo("#how-it-works")}>{props.CTALabel}</button>
                                    : <a href={props.CTAUrl} className="btn">{props.CTALabel}</a>
                                : ""
                        }
                    </div>
                </div>
                {/* </div> */}
            </BackgroundImage>
            <div className="secondSection_parallax" style={{ textAlign: props.alignment }}>
                {
                    props.paragraphTitle ?
                        <h2>{props.paragraphTitle}</h2>
                        : ""
                }
                {
                    props.paragraphContent ? props.paragraphContent.map((item, index) => {
                        return (
                            item.boldText ?
                                <p key={index}><b>{item.boldText}</b></p>
                                :
                                <p key={index}>{item.text}</p>
                        )
                    })
                        : ""
                }
                {
                    props.paragraphConclusion ?
                        <b>{props.paragraphConclusion}</b>
                        : ""
                }
            </div>
        </div>
    )
}

export default ParallaxComponent;